import React from 'react'
import { connect } from 'react-redux'

import Layout from '../../../../components/Layout'
import BrandTabs from '../../../../components/marketplace/brand/BrandTabs'

class BrandTracking extends React.Component {
  render() {
    const { brandTrackerLink, user } = this.props
    const dellUser = user && user.email && user.email.includes('@dell.com')
    return (
      <Layout noFooter active="brand" requireAuth>
        {!dellUser && (
          <>
            <BrandTabs active="tracking" />
            <iframe title="brand_infogram" src={brandTrackerLink} className="full-page-iframe" />
          </>
        )}
        {dellUser && (
          <iframe
            title="brand_infogram"
            src="https://infogram.com/dell-brand-pageintelligence-insights-live-1ho16vol51kd74n?live"
            className="full-page-iframe"
          />
        )}
      </Layout>
    )
  }
}

export default connect(
  state => ({
    brandTrackerLink: state.brandReports.trackInfogram,
    user: state.user.user,
  }),
  {}
)(BrandTracking)
